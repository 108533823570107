import * as React from 'react'

export default (props: any) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.83353 13.9531L11.2448 11.9501C11.8702 11.7547 12.1783 11.0264 11.8928 10.418L7.76462 1.61879C7.66576 1.40808 7.51627 1.25001 7.34206 1.14639M4.83353 13.9531C4.40695 14.0864 3.94539 13.9296 3.67799 13.5605L1.21237 10.1579C0.942642 9.78569 0.928612 9.27791 1.17735 8.89033L5.92599 1.49078C6.25516 0.977855 6.88406 0.873979 7.34206 1.14639M4.83353 13.9531L7.34206 1.14639"
      stroke="#96A4B8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
